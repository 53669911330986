import ClientOnly from 'vue-client-only'
import { Panel } from '@common/panel'
import { use, connect, disconnect } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
])

export const ChartsLine = {
  mixins: [Panel],

  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '220px',
    },
    group: {
      type: String,
      default: null,
    },
    connect: {
      type: Boolean,
      default: false,
    },
    data: Object,
  },

  components: {
    ClientOnly,
    VChart,
  },

  provide: {
    [THEME_KEY]: 'light',
  },

  methods: {
    chartResize(timer, ms = 100) {
      if (this.$refs.chart) this.$refs.chart.resize({ width: 1 })
      clearTimeout(timer)
      timer = setTimeout(() => {
        if (this.$refs.chart) this.$refs.chart.resize({ width: 'auto' })
      }, ms)
    },
  },

  watch: {
    data() {
      this.$mergeDeep(this.cachePayload, this.data, null, false)
      this.panelFill()
    },

    '$store.state.ui.aside'(value) {
      if (!this.$store.getters['ui/isMediumDevice'] && value) {
        this.chartResize(this.timer.aside, 800)
      }
    },

    '$store.state.ui.windowWidth'() {
      this.chartResize(this.timer.resize)
    },
  },

  data() {
    return {
      loadingOptions: {
        text: '',
        spinnerRadius: 15,
        lineWidth: 5,
        color: this.$wc.conf.base.colors.wcsecondary,
        maskColor: this.$wc.conf.base.colors.wcshadow,
      },
      timer: {
        aside: null,
        resize: null,
      },
      chart: {
        textStyle: {
          fontFamily: 'Montserrat,Helvetica Neue,Calibri,Arial,sans-serif',
        },
        tooltip: {
          trigger: 'axis',
          extraCssText: 'z-index: 90;',
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
        ],
        color: [
          this.$wc.conf.base.colors.wcsecondary,
          this.$wc.conf.base.colors.wcprimary,
          '#5470c6',
          '#91cc75',
          '#fac858',
          '#ee6666',
          '#73c0de',
          '#3ba272',
          '#fc8452',
          '#9a60b4',
          '#ea7ccc',
        ],
      },
    }
  },

  mounted() {
    if (this.connect) connect(this.group)
  },

  destroyed() {
    if (this.connect) disconnect(this.group)
  },
}
