import 'leaflet/dist/leaflet.css'
import ClientOnly from 'vue-client-only'
import { GeoLocation } from '@common/geolocation'

/* Base Leaflet js library. */
export let Leaflet = {}

/* Vue Leaflet js wrapper. */
export let Vue2Leaflet = {}

/* Vue Leaflet js wrapper. */
export let Vue2LeafletHotline = {}

/* Client side only import. */
if (process.client || process.spa) {
  Leaflet = require('leaflet')
  Vue2Leaflet = require('vue2-leaflet')
  Vue2LeafletHotline = require('vue2-leaflet-hotline/Vue2LeafletHotline').default

  delete Leaflet.Icon.Default.prototype._getIconUrl
}

/* Map component mixin. */
export const Map = {
  mixins: [GeoLocation],

  components: {
    ClientOnly,
    'l-map': Vue2Leaflet.LMap,
    'l-tile-layer': Vue2Leaflet.LTileLayer,
    'l-marker': Vue2Leaflet.LMarker,
    'l-polyline': Vue2Leaflet.LPolyline,
    'l-hotline': Vue2LeafletHotline,
  },

  methods: {
    onMapReady(map) {
      map.addControl(
        Leaflet.control.attribution({
          position: 'bottomright',
          prefix: '',
        })
      )
    },
  },

  data() {
    return {
      leaflet: {
        zoom: 14,
        center: [0, 0],
        url: process.env.VUE_APP_WC_CORE_MAP_URL,
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
        icon:
          process.client || process.spa
            ? Leaflet.divIcon({
                html: '<i class="fas fa-4x fa-map-marker-alt text-secondary"></i>',
                iconSize: [36, 48],
                iconAnchor: [18, 48],
                className: 'wc-map-marker-icon',
              })
            : {},
        marker: {
          latlon: [0, 0],
        },
        hotline: {
          min: 0,
          max: 1,
          palette: {},
        },
      },
    }
  },
}
