export const GeoPosition = (options) =>
  new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  )

export const GeoPositionObject = ({ coords, timestamp }) => ({
  coords: {
    lat: coords.latitude,
    lon: coords.longitude,
    alt: coords.altitude,
    accuracy: coords.accuracy,
    altAccuracy: coords.altitudeAccuracy,
    heading: coords.heading,
    speed: coords.speed,
  },
  timestamp,
})

export const GeoLocation = {
  computed: {
    geoLocationSupport() {
      return typeof window !== 'undefined' && navigator && 'geolocation' in navigator
    },
  },

  methods: {
    async geoPosition() {
      if (!this.geoLocationSupport) return

      const response = await GeoPosition(this.geolocation.options)
      if (!response || !response.coords) {
        return (this.geolocation.position = null)
      }
      return (this.geolocation.position = GeoPositionObject(response))
    },
  },

  data() {
    return {
      geolocation: {
        options: {
          timeout: 5000,
          maximumAge: 0,
          enableHighAccuracy: true,
        },
        position: null,
      },
    }
  },
}
