<template lang="pug">
  transition(name='fade')
    b-card.ls-machines-analytics-panel-map.card-white.card-no-gutters.shadow(no-body)
      b-card-header.bg-transparent.border-0
        h5.m-0
          i.fa-fw.fal.fa-map-marked-alt
          |  {{ $t('analytic.titles.map') }}
      b-card-body.d-flex.justify-content-center.align-items-center.p-0
        client-only
          l-map.wc-map(
            ref='lMap'
            :class='styleClass'
            :zoom='leaflet.zoom'
            :center='leaflet.center'
            :options='{ attributionControl: false }'
            @ready='onMapReady')
            l-tile-layer(
              :url='leaflet.url'
              :attribution='leaflet.attribution')
            l-hotline(
              v-if='panelShow'
              :lat-lngs='leaflet.hotline.latlngs'
              :min='leaflet.hotline.min'
              :max='leaflet.hotline.max'
              :palette='leaflet.hotline.palette'
              :weight='3'
              :outline-width='0'
              outline-color='transparent')
</template>

<script>
import { Panel } from '@common/panel'
import { Map, Leaflet } from '@common/map'

export default {
  name: 'ls-machines-analytics-panel-map',
  mixins: [Panel, Map],
  props: {
    namespace: String,
    styleClass: String,
    data: Object,
  },
  watch: {
    data() {
      this.$mergeDeep(this.cachePayload, this.data, null, false)
      this.panelFill()
    },
    'panel.data'() {
      this.leaflet.hotline.latlngs = []
      if ((this.$getDeep(this.panel.data, 'attributes.chart.connect') || []).length) {
        this.$refs.lMap.fitBounds(
          new Leaflet.LatLngBounds(this.panel.data.attributes.chart.connect)
        )
        this.leaflet.hotline.min = this.panel.data.attributes.chart.connect[0][2] || 0
        this.leaflet.hotline.max = this.panel.data.attributes.chart.connect.at(-1)[2] || 1
        this.leaflet.hotline.palette = {}
        this.leaflet.hotline.palette[0.0] = this.$wc.conf.base.colors.wcprimary
        this.leaflet.hotline.palette[1.0] = this.$wc.conf.base.colors.wcsecondary
        this.leaflet.hotline.latlngs = this.panel.data.attributes.chart.connect
      }
    },
  },
  data() {
    return {
      cacheType: `${this.namespace}/getPanelMap`,
      cacheOptions: { timeout: this.$wc.conf.cache.charts },
      cachePayload: { machine_id: this.$route.params.machine_id, ...this.data },
      skipNotifications: true,
      leaflet: {
        zoom: 10,
        center: [46.4667566, 11.3234873],
        hotline: {
          latlngs: [],
        },
      },
    }
  },
}
</script>
