<template lang="pug">
  transition(name='fade')
    b-card.ls-machines-analytics-panel-operating-hours.card-white.card-no-gutters.shadow(no-body)
      b-card-header.bg-transparent.border-0
        h5.m-0
          i.fa-fw.fal.fa-stopwatch
          |  {{ $t('analytic.titles.operating_hours') }}
      b-card-body.d-flex.justify-content-center.align-items-center.pt-0
        .chart-wrapper(:style='`width: ${width}; height: ${height};`')
          client-only
            v-chart.chart(
              ref='chart'
              :option='chart'
              :loading='isLoading'
              :loadingOptions='loadingOptions'
              :group='group'
              autoresize)
</template>

<script>
import { ChartsLine } from '@common/charts/line'

export default {
  name: 'ls-machines-analytics-panel-operating-hours',
  mixins: [ChartsLine],
  props: {
    namespace: String,
    animation: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    'panel.data'() {
      this.chart.legend.data = Object.keys(this.panel.data.attributes.chart).map((x) =>
        this.$t(`analytic.charts.operating_hours.legends.${x}`)
      )
      for (const [key, value] of Object.entries(this.panel.data.attributes.chart)) {
        const name = this.$t(`analytic.charts.operating_hours.legends.${key}`)
        const series = this.chart.series.find((x) => x.name === name)
        if (series) {
          series.data = value
        } else {
          this.chart.series.push({
            name: name,
            type: 'line',
            showSymbol: false,
            data: value,
          })
        }
      }
    },
  },
  data() {
    return {
      cacheType: `${this.namespace}/getPanelOperatingHours`,
      cacheOptions: { timeout: this.$wc.conf.cache.charts },
      cachePayload: { machine_id: this.$route.params.machine_id, ...this.data },
      skipNotifications: true,
      chart: {
        animation: this.animation,
        grid: {
          top: 15,
          bottom: '25%',
          left: 50,
          right: 12,
        },
        legend: {
          data: [],
          bottom: 0,
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          axisLabel: {
            hideOverlap: true,
          },
        },
        yAxis: {
          type: 'value',
          name: this.$t('analytic.charts.operating_hours.axis.hours'),
          nameLocation: 'center',
          nameGap: 38,
          min(value) {
            return value.min ? value.min : 0
          },
          max(value) {
            return value.max ? value.max : 1000
          },
        },
        series: [],
      },
    }
  },
}
</script>
